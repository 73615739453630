import React, { useState, useRef } from 'react';
import axios from 'axios';

const Home = () => {
  const ingrd = ['Daging (Ayam, Sapi, Kambing, dll)', 'Ikan & Seafood', 'Produk susu', 'Biji-bijian', 'Sayuran', 'Buah-Buahan', 'Rempah-rempah dan Bumbu', 'Produk Olahan', 'Minuman', 'Kacang-Kacangan', 'Telur'];

  const formModalRef = useRef(null);
  const loadingModalRef = useRef(null);
  const loadingModalAdvRef = useRef(null);
  const resultModalRef = useRef(null);
  const formModalAdvRef = useRef(null);
  const resultModalAdvRef = useRef(null);

  // Menu Toggle
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const removeBackdrop = () => {
    const backdrops = document.querySelectorAll('.modal-backdrop');
    backdrops.forEach((backdrop) => backdrop.remove());
  };

  const hideModal = (modalRef) => {
    const modal = window.bootstrap.Modal.getInstance(modalRef.current);
    if (modal) modal.hide();
    removeBackdrop();
  };

  const showModal = (modalRef) => {
    removeBackdrop();
    const modal = new window.bootstrap.Modal(modalRef.current);
    modal.show();
  };

  const [formData, setFormData] = useState({
    businessName: '',
    businessType: '',
    location: '',
    foodType: [],
    mainIngredients: [],
    supplierDetails: '',
    productionMethod: '',
    productionScale: '',
    name: '',
    phone: '',
    email: '',
    businessName2: ''
  });

  //Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type == 'checkbox') {
      setFormData((prevData) => {
        const updatedArray = checked
          ? [...prevData[name], value] // Add value if checked
          : prevData[name].filter((item) => item !== value); // Remove value if unchecked

        return { ...prevData, [name]: updatedArray };
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  //Handle form subission
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    hideModal(formModalRef);
    showModal(loadingModalRef);

    console.log('Form Data:', formData);

    try {
      // const response = await axios.post(process.env.REACT_APP_API_URL, formData, {
      const response = await axios.post('https://siaphalal.com/siaphalalapi/submit-form', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'crossdomain': true
        }
      });
      setResponseMessage(response.data.message);
      console.log('Response:', response.data);
    } catch (error) {
      setResponseMessage('Data gagal disubmit, Silahkan coba lagi!');
      console.log('Error:', error);
    } finally {
      hideModal(loadingModalRef);
      showModal(resultModalRef);
    }
  }

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    hideModal(formModalAdvRef);
    showModal(loadingModalAdvRef);

    // Define to form data object
    const formData = {
      name: e.target.name.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      businessName: e.target.businessName2.value,
    };

    try {
      // const response = await axios.post(process.env.REACT_APP_API_URL_ADV, formData, {
      const response = await axios.post('https://siaphalal.com/siaphalalapi/request-consultation', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'crossdomain': true
        }
      });

      setResponseMessage("Data telah disubmit!");
      console.log('Response:', response.data);
    } catch (error) {
      setResponseMessage('Data gagal disubmit, Silahkan coba lagi!');

    } finally {
      setTimeout(() => {
        hideModal(loadingModalAdvRef);
        showModal(resultModalAdvRef);
      }, 1000);
    }
  }

  const ref = useRef(null);
  const handleClick = (event) => {
    event.preventDefault();
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleClickLanjutan = (event) => {
    event.preventDefault();
    hideModal(resultModalRef);
    showModal(formModalAdvRef);
  };

  return (
    <div className="wrapper">
      <header className="container position-fixed start-50 translate-middle-x">
        {/* <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="#"><img src={require('../assets/logo.png')} /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={handleClick}>Tentang Kami</a>
                </li>
                <li className="nav-item">
                  <button className="nav-link btn btn-main" data-bs-toggle="modal" data-bs-target="#modalForm">Konsultasi Gratis</button>
                </li>
              </ul>
            </div>
          </div>
        </nav> */}

        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="#"><img src={require('../assets/logo.png')} /></a>
            <button
              className="navbar-toggler"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >

              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`navbar-collapse ${isMenuOpen ? 'open' : ''}`} id="navbarTogglerDemo02">
              <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={handleClick}>Tentang Kami</a>
                </li>
                <li className="nav-item">
                  <button className="nav-link btn btn-main" data-bs-toggle="modal" data-bs-target="#modalForm">Konsultasi Gratis</button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section className="bg-hero pb-0">
        <div className="container bg-circle">
          <h1 className="text-center p-4">
            Sertifikasi Halal <img src={require('../assets/halal.png')} alt="" /><br />
            <span>Mudah & Cepat</span> dengan Konsultasi Modern <span>Berbasis AI</span>
          </h1>
          <p>
            Dapatkan panduan sertifikasi halal yang cepat dan akurat untuk bisnis F&B Anda dari AI kami, disupervisi langsung oleh ahli bersertifikat MUI. Proses mudah dan terjangkau, mulai dari pendaftaran hingga sertifikasi. Semua bisa diakses kapan saja, di mana saja.
          </p>
          <div className="mb-5">
            <div className="testi">
              <img src={require('../assets/rate.png')} alt="rating" />
              <div className="rate-in">
                <img src={require('../assets/star.png')} alt="5 star" />
                <span>
                  Teknologi AI untuk <span>mempermudah<br></br>
                    sertifikasi halal</span> bisnis Anda.
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-5 btn-hero">
            <button className="btn btn-main" data-bs-toggle="modal" data-bs-target="#modalForm">
              Konsultasi Gratis <i><img src={require('../assets/ic-arrow.png')} /></i>
            </button>
            <button className="btn btn-white btn-about" onClick={handleClick}>
              Pelajari Lebih Lanjut
            </button>
          </div>
        </div>

        <div className="feature">
          <div className="container px-4">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3 px-3">
                <div className="feature--card">
                  <h3>
                    Mengapa Perlu Sertifikasi Halal? 🤔
                  </h3>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 px-3">
                <div className="feature--card">
                  <h3>
                    Mampu Tingkatkan Omzet dengan Pasar Muslim
                  </h3>
                  <p>
                    Dengan lebih dari 230 juta konsumen Muslim, sertifikasi halal bisa meningkatkan penjualan hingga 40%.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 px-3">
                <div className="feature--card">
                  <h3>
                    Taat Regulasi, Bisnis Tanpa Hambatan
                  </h3>
                  <p>
                    Memastikan bisnis Anda mematuhi hukum dan terhindar dari risiko denda atau penarikan produk, menjaga kelancaran operasional dan reputasi.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3 px-3">
                <div className="feature--card">
                  <h3>
                    Reputasi Halal, Loyalitas Pelanggan Meningkat
                  </h3>
                  <p>
                    Sertifikasi halal meningkatkan kepercayaan dan loyalitas pelanggan, membuat bisnis Anda akan semakin kuat dan stabil.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section ref={ref}>
        <h2 className="text-center fw-bold mb-5">
          Keunggulan SiapHalal Dibandingkan Konsultan Halal Konvensional
        </h2>
        <div className="pricing">
          <table className="table">
            <tbody>
              <tr style={{ borderTop: 'none' }}>
                <th colSpan={2} className="no-border"></th>
                <td className="text-center rounded-tl-lg rounded-tr-lg text-white" rowSpan={2}>
                  <img src={require('../assets/sparkling.png')} alt="" />SiapHalal Modern Consultancy 🤩
                </td>
              </tr>
              <tr style={{ borderTop: 'none' }}>
                <th colSpan={1} className="no-border"></th>
                <td className="text-center rounded-tl-lg border-top border-start" style={{borderRight: 'none'}}>
                  Konsultan Halal Konvensional 😐
                </td>
              </tr>
              <tr>
                <th className="rounded-tl-lg border-top">
                  Kecepatan
                </th>
                <td>
                  Proses bisa memakan waktu berminggu-minggu hingga berbulan-bulan.
                </td>
                <td>
                  Hasil konsultasi instan dalam hitungan detik dengan teknologi AI kami.
                </td>
              </tr>
              <tr>
                <th>
                  Aksesibilitas
                </th>
                <td>
                  Harus membuat janji dan bertemu tatap muka, terbatas oleh lokasi.
                </td>
                <td>
                  Dapat diakses kapan saja dan di mana saja secara online.
                </td>
              </tr>
              <tr>
                <th>
                  Biaya Awal
                </th>
                <td>
                  Memerlukan biaya konsultasi awal yang tinggi untuk setiap pertemuan.
                </td>
                <td>
                  Konsultasi AI gratis, tanpa biaya di awal.
                </td>
              </tr>
              <tr>
                <th>
                  Penyesuaian Harga
                </th>
                <td>
                  Harga tetap dan biasanya tidak fleksibel, sulit bagi usaha kecil.
                </td>
                <td>
                  Biaya konsultasi eksklusif dapat disesuaikan dengan skala bisnis Anda.
                </td>
              </tr>
              <tr>
                <th>
                  Pengalaman
                </th>
                <td>
                  Mengandalkan pengalaman konsultan individu.
                </td>
                <td>
                  AI kami didukung dan diawasi oleh konsultan bersertifikat MUI.
                </td>
              </tr>
              <tr>
                <th>
                  Transparansi Proses
                </th>
                <td>
                  Informasi dan langkah-langkah sering kali tidak jelas dan membingungkan.
                </td>
                <td>
                  Setiap tahapan dijelaskan dengan detail, Anda tahu persis apa yang harus dilakukan.
                </td>
              </tr>
              <tr>
                <th className="rounded-bl-lg">
                  Efisiensi
                </th>
                <td>
                  Menghabiskan banyak waktu untuk koordinasi dan pengurusan manual.
                </td>
                <td className="rounded-br-lg rounded-bl-lg" style={{ borderBottom: "2px solid #7737E3" }}>
                  Otomatisasi proses yang efisien, mengurangi waktu dan upaya yang diperlukan.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section>
        <div className="container">
          <h2 className="text-center">
            Cara Menggunakan SiapHalal
          </h2>
          <p className="text-center mb-5">
            Ikuti langkah mudah berikut untuk memulai konsultasi halal gratis dengan SiapHalal, hingga mendapatkan sertifikasi halal yang terjamin.
          </p>

          <div className="d-flex justify-content-center step-box gap-5">
            <div className="text-center">
              <img src={require('../assets/step-1.png')} alt="" />
              <h3 className="text-xl font-semibold mb-2">
                Masukkan Informasi Dasar Bisnis Anda
              </h3>
              <p>
                Cukup input beberapa informasi dasar tentang bisnis F&B Anda seperti jenis produk, lokasi, dan kapasitas produksi. Sistem kami akan memproses data ini untuk memberikan konsultasi yang relevan.
              </p>
            </div>
            <div className="text-center mx-16">
              <img src={require('../assets/step-2.png')} alt="" />
              <h3 className="text-xl font-semibold mb-2">
                Dapatkan Hasil Konsultasi dalam Hitungan Detik
              </h3>
              <p>
                Dalam beberapa detik, AI kami akan memberikan panduan langkah demi langkah secara gratis, mencakup persyaratan, perkiraan biaya, dan saran spesifik untuk bisnis Anda.
              </p>
            </div>
            <div className="text-center">
              <img src={require('../assets/step-3.png')} alt="" />
              <h3 className="text-xl font-semibold mb-2">
                Lanjutkan dengan Konsultasi Eksklusif Jika Diperlukan
              </h3>
              <p>
                Jika Anda ingin bimbingan penuh hingga sertifikasi halal resmi diperoleh, Anda bisa memesan konsultasi eksklusif. Kami akan membantu hingga proses selesai, dengan biaya kompetitif yang disesuaikan dengan skala bisnis Anda.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <h2 className="text-3xl font-bold text-center mb-4">
            Pertanyaan yang Sering Diajukan (FAQ)
          </h2>
          <p className="text-center mb-5">
            Ada pertanyaan seputar layanan SiapHalal? Temukan jawaban untuk pertanyaan umum mengenai proses konsultasi AI kami, biaya, dan layanan eksklusif dalam bagian FAQ berikut ini.
          </p>
          <div className="max-w-2xl mx-auto space-y-4 mb-5">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Apa itu SiapHalal?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    SiapHalal adalah platform konsultasi sertifikasi halal berbasis AI yang memberikan panduan instan dan gratis untuk bisnis F&B di Indonesia. Kami juga menawarkan konsultasi eksklusif dengan pendampingan penuh hingga bisnis Anda mendapatkan sertifikasi halal resmi.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Apakah layanan konsultasi AI benar-benar gratis?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Ya, konsultasi AI kami sepenuhnya gratis. Anda hanya perlu memasukkan informasi dasar bisnis Anda, dan kami akan memberikan panduan yang disupervisi oleh ahli bersertifikat MUI.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Berapa lama waktu yang dibutuhkan untuk mendapatkan hasil konsultasi?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Hasil konsultasi dari AI kami akan diberikan secara instan, hanya dalam hitungan detik setelah Anda menginput data bisnis.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Apa yang dimaksud dengan 'Konsultasi Eksklusif'?
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Konsultasi Eksklusif adalah layanan berbayar di mana tim ahli kami akan mendampingi Anda secara langsung dalam seluruh proses sertifikasi halal, mulai dari persyaratan hingga sertifikat halal resmi diterbitkan.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
                    Berapa biaya untuk Konsultasi Eksklusif?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Biaya untuk Konsultasi Eksklusif bervariasi dan dapat disesuaikan dengan skala bisnis Anda, sehingga tetap terjangkau bagi usaha kecil maupun besar.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseFour">
                    Apakah saya harus langsung memesan Konsultasi Eksklusif setelah menggunakan layanan gratis?
                  </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Tidak, Anda bebas menggunakan layanan konsultasi AI gratis kami tanpa harus memesan Konsultasi Eksklusif. Layanan eksklusif adalah pilihan tambahan jika Anda memerlukan bimbingan lebih lanjut.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseFour">
                    Bagaimana cara memulai proses konsultasi?
                  </button>
                </h2>
                <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Cukup kunjungi halaman utama SiapHalal, masukkan informasi dasar tentang bisnis Anda, dan dalam beberapa detik, Anda akan mendapatkan panduan dari AI kami.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-section text-center">
        <div className='container'>
          <h2 className="fw-light mb-8">
            Mulai Konsultasi Gratis Sekarang! <img className="d-inline rounded-pill" src={require('../assets/cta-1.png')} alt="" /> SiapHalal Membantu Bisnis Anda Mendapatkan <img className="d-inline rounded-pill" src={require('../assets/cta-2.png')} alt="" /> <span className="fw-bold"> Sertifikasi Halal Tanpa Hambatan!</span>
            <br />
          </h2>
          <p className="text-sm mb-4">
            Proses otomatis, tanpa ribet, dan lebih efisien.
          </p>

          <div className="d-flex justify-content-center">
            <button className="btn btn-main" data-bs-toggle="modal" data-bs-target="#modalForm">
              Konsultasi Gratis <i><img src={require('../assets/ic-arrow.png')} /></i>
            </button>
          </div>
        </div>
      </section>

      <footer className="py-5 text-center bg-white">
        <div className="container">
          <div className="mx-auto">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="fw-bold grayscale">
                <img src={require('../assets/logo.png')} alt="" />
              </div>

              <div className="foot--link">
                <div className="d-flex justify-content-end align-items-center">
                  <a href="#" onClick={handleClick}>Tentang Kami</a>
                  <a href="#" className="ml-3" data-bs-toggle="modal" data-bs-target="#modalForm">Konsultasi Gratis</a>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className='text-start'>
                <p className="mb-3">
                  Solusi modern untuk sertifikasi halal<br />dengan bantuan AI yang cepat dan efisien.
                </p>

                <small>© 2024 SiapHalal, Inc.</small>
              </div>
              <div className="d-flex justify-content-end align-items-center gap-2 socmed">
                <a href="https://www.instagram.com/siaphalalcom" target="_blank"><img src={require('../assets/ic-ig.png')} alt="Instagram" /></a>
                <a className="ml-4" href="https://www.linkedin.com/company/siaphalal/" target="_blank"><img src={require('../assets/ic-in.png')} alt="Linked In" /></a>
              </div>
            </div>
          </div>
        </div>
      </footer >

      {/* Form Modal */}
      <div className="modal fade" ref={formModalRef} id="modalForm" tabIndex="-1" aria-labelledby="modalForm" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content px-4 py-3">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalForm">Konsultasi Gratis</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>


            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="businessName" className="form-label">Nama Bisnis</label>
                  <input type="text" className="form-control" name="businessName" id="businessName" value={formData.businessName} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label htmlFor="businessType" className="form-label">Jenis bisnis</label>
                  <select className="form-select" name="businessType" id="businessType" value={formData.businessType} onChange={handleChange} required >
                    <option defaultValue>Pilih jenis bisnis</option>
                    <option value="Restoran">Restoran</option>
                    <option value="Manufaktur Makanan">Manufaktur Makanan</option>
                    <option value="Katering">Katering</option>
                    <option value="Bisnis Rumahan">Bisnis Rumahan</option>
                    <option value="Lainnya">Lainnya</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="location" className="form-label">Lokasi (Kota)</label>
                  <input type="text" className="form-control" name="location" id="location" value={formData.location} onChange={handleChange} required />
                  {/*}
                    <select className="form-select" name="location" id="location" value={formData.location} onChange={handleChange} >
                      <option defaultValue>Pilih lokasi</option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                    {*/}
                </div>
                <div className="mb-3">
                  <label className="form-label">Jenis Makanan/Produk</label>
                  <div className="row row-cols-3 ms-1">
                    {['Makanan ringan', 'Minuman', 'Makanan utama', 'Makanan penutup', 'Makanan kemasan', 'Lainnya'].map((food, index) => (
                      <div className="form-check col" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="foodType"
                          value={food}
                          onChange={handleChange}
                          checked={formData.foodType.includes(food)}
                        />
                        <label className="form-check-label">{food}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Bahan Utama</label>
                  <div className="row row-cols-3 ms-1">
                    {ingrd.map((ingredients, index) => (
                      <div className="form-check col" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="mainIngredients"
                          value={ingredients}
                          onChange={handleChange}
                          checked={formData.mainIngredients.includes(ingredients)}
                        />
                        <label className="form-check-label">
                          {ingredients}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Detail Pemasok/Supplier</label>
                  <div className="row row-cols-3 ms-1">
                    {['Pemasok lokal', 'Pemasok internasional', 'Campuran', 'Tidak yakin'].map((supplier, index) => (
                      <div className="form-check col" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="supplierDetails"
                          value={supplier}
                          onChange={handleChange}
                          checked={formData.supplierDetails === supplier}
                          required
                        />
                        <label className="form-check-label">{supplier}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Metode Produksi</label>
                  <div className="row row-cols-3 ms-1">
                    {['Manual', 'Semi automatic', 'Fully automatic'].map((method, index) => (
                      <div className="form-check col" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="productionMethod"
                          value={method}
                          onChange={handleChange}
                          checked={formData.productionMethod === method}
                          required
                        />
                        <label className="form-check-label">{method}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Skala Produksi</label>
                  <div className="row row-cols-3 ms-1">
                    {['Skala kecil', 'Skala menengah', 'Skala besar', 'Industri'].map((scale, index) => (
                      <div className="form-check col" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="productionScale"
                          value={scale}
                          onChange={handleChange}
                          checked={formData.productionScale === scale}
                          required
                        />
                        <label className="form-check-label">{scale}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <button type="submit" className="btn btn-main mx-auto">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Loading Modal */}
      <div className="modal fade" ref={loadingModalRef} id="modalLoading" tabIndex="-1" aria-labelledby="modalForm" aria-hidden="true" data-bs-backdrop="static">
        <div className="modal-dialog modal-lg">
          <div className="modal-content px-4 py-3">
            <p className="text-center fade-animation">Sedang meracik solusi terbaik untuk bisnis Anda... ✨ Sertifikasi halal semakin dekat!</p>
          </div>
        </div>
      </div>

      {/* Result Modal */}
      <div className="modal fade" ref={resultModalRef} id="modalResult" tabIndex="-1" aria-labelledby="modalForm" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content px-4 py-3">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalForm">Hasil Konsultasi</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body response">
              <div dangerouslySetInnerHTML={{ __html: responseMessage }} />
            </div>

            {/* Invitation for Exclusive Consultation */}
            <div className="exclusive-invitation">
              <p>Ingin bimbingan lebih lanjut? Dapatkan pendampingan penuh konsultan halal kami hingga sertifikasi halal resmi jadi, dengan Konsultasi Eksklusif! Harga fleksibel sesuai skala bisnis Anda!</p>
              <button className="btn btn-main" onClick={handleClickLanjutan}>
                Daftar Konsultasi Eksklusif!
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Lanjutan */}
      <div className="modal fade" ref={formModalAdvRef} id="modalFormLanjutan" tabIndex="-1" aria-labelledby="modalFormLanjutan" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content px-4 py-3">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalForm">Konsultasi eksklusif</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit2}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Nama</label>
                  <input type="text" className="form-control" name="name" id="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Telepon</label>
                  <input type="text" className="form-control" name="phone" id="phone" value={formData.phone} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="text" className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label htmlFor="businessName2" className="form-label">Nama Bisnis</label>
                  <input type="text" className="form-control" name="businessName2" id="businessName2" value={formData.businessName2} onChange={handleChange} required />
                </div>

                <button type="submit" className="btn btn-main mx-auto">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Loading Modal Lanjutan */}
      <div className="modal fade" ref={loadingModalAdvRef} id="modalLoading" tabIndex="-1" aria-labelledby="modalForm" aria-hidden="true" data-bs-backdrop="static">
        <div className="modal-dialog modal-lg">
          <div className="modal-content px-4 py-3">
            <p className="text-center fade-animation">Mendaftar untuk Konsultasi Eksklusif... Tunggu sebentar, kami sedang memproses permohonan Anda!</p>
          </div>
        </div>
      </div>

      {/* Result Modal Lanjutan */}
      <div className="modal fade" ref={resultModalAdvRef} id="modalResult" tabIndex="-1" aria-labelledby="modalForm" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content px-4 py-3">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalForm">Pendaftaran Berhasil!</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body response">
              <p>Pendaftaran Konsultasi Eksklusif Anda berhasil! 🎉 Kami akan menghubungi Anda segera untuk langkah selanjutnya. Terima kasih telah memilih kami!</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Home;